@import "../../../src/scss/themes/basic/abstracts/custom_variables";
@import "_default/block_content_image_switch";

.block_content_image_switch {
    .close-video {
        background-color: $color-secondary-light;
    }

    .youtube-holder .embed_media {
        background-color: transparent;
    }

    &.reversed {
        background-color: $color-secondary-bright;

        &:before {
            content: '';
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAxIiBoZWlnaHQ9IjEwMSIgdmlld0JveD0iMCAwIDEwMSAxMDEiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjY2MDE1NiAzNC4zMjJWNjcuNjQ2N1YxMDAuOTg0SDM0LjAwMjJWNjcuNjQ2N1YzNC4zMjJWMC45ODQzNzVIMC42NjAxNTZWMzQuMzIyWiIgZmlsbD0iIzAwRDg4MCIvPgo8cGF0aCBkPSJNNjcuMzMxIDM0LjMzNTlMMzQuMDAyIDY3LjY2MDZMNjcuMzMxIDEwMC45ODVMMTAwLjY2IDY3LjY2MDZMNjcuMzMxIDM0LjMzNTlaIiBmaWxsPSIjMDBEODgwIi8+Cjwvc3ZnPgo=");
            background-size: 100% auto;
            position: absolute;
            width: 80px;
            height: 80px;
            left: 16px;
            z-index: 1;

            @include breakpoint(large down) {
                top: -20px;
            }

            @include breakpoint(xlarge) {
                width: 101px;
                height: 101px;
                left: calc(50vw - 33.34px);
                bottom: 0;
            }
        }

        .close-video {
            background-color: $color-secondary-light;
        }

        .youtube-holder .embed_media {
            background-color: transparent;
        }
    }
}
