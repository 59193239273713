//Theme variables
$theme-name: 'basic';

//Defaults
$default-size: 16px;

//Colors
$color-primary: #001F32;
$color-secondary-hard: #165233;
$color-secondary-medium: #0A9640;
$color-secondary-light: #00D880;
$color-secondary-bright: #D2F5E1;
$color-tertiary: #7F57EE;
$color-gray: #808080;
$color-form-borders: $color-gray;
$color-error: #E91A1B;

//Hard colors
$color-black: #000000;
$color-black-54: #757575;
$color-black-32: #ADADAD;
$color-black-10: #E3E3E3;
$color-black-4: #F5F5F5;
$color-white: #FFFFFF;

//Font variables
$mainfont: 'DM Sans';

//Font weights
$light:      300;
$regular:    400;
$medium:     500;
$semibold:   600;
$bold:       700;
$extrabold:  800;
$black:      900;

//Headings
$heading-h1-max: ceil(($default-size * 2.5));     // ~40px
$heading-h1-min: ceil(($default-size * 2));  // ~32px
$heading-h2-max: ceil(($default-size * 2.125));     // ~34px
$heading-h2-min: ceil(($default-size * 1.625));  // ~26px
$heading-h3-max: ceil(($default-size * 1.75));     // ~28px
$heading-h3-min: ceil(($default-size * 1.5));  // ~24px
$heading-h4-max: ceil(($default-size * 1.5));  // ~24px
$heading-h4-min: ceil(($default-size * 1.375));   // ~22px
$heading-h5:     ceil(($default-size * 1.25)); // ~20px
$heading-h6:     ceil(($default-size * 1.125));     // ~18px

//Text
$font-l:            ceil(($default-size * 1.25));      // ~20px
$font-m:            ceil(($default-size * 1.0625)); // ~17px
$font-s:            ceil(($default-size * 0.9375));  // ~15px
$font-xs:           ceil(($default-size * 0.875));   // ~14px
$font-xxs:           ceil(($default-size * 0.8125));   // ~13px
$font-xxxs:           ceil(($default-size * 0.75));   // ~12px

//Other text styles
$font-quote-max: ceil(($default-size * 2));      // ~32px
$font-quote-min: ceil(($default-size * 1.5625));      // ~25px

//Font settings
$text-settings: (
        'font-heading-h1': (
                font-family: $mainfont,
                font-size: clamp($heading-h1-min, 4vw, $heading-h1-max),
                font-weight: $bold,
                line-height: 120%,
                //letter-spacing: 0
        ),
        'font-heading-h2': (
                font-family: $mainfont,
                font-size: clamp($heading-h2-min, 4vw, $heading-h2-max),
                font-weight: $bold,
                line-height: 130%,
                //letter-spacing: 0
        ),
        'font-heading-h3': (
                font-family: $mainfont,
                font-size: clamp($heading-h3-min, 4vw, $heading-h3-max),
                font-weight: $bold,
                line-height: 140%,
                //letter-spacing: 0
        ),
        'font-heading-h4': (
                font-family: $mainfont,
                font-size: clamp($heading-h4-min, 4vw, $heading-h4-max),
                font-weight: $bold,
                line-height: 145%,
                //letter-spacing: 0
        ),
        'font-heading-h5': (
                font-family: $mainfont,
                font-size: $heading-h5,
                font-weight: $bold,
                line-height: 150%,
                //letter-spacing: 0
        ),
        'font-heading-h6': (
                font-family: $mainfont,
                font-size: $heading-h6,
                font-weight: $bold,
                line-height: 150%,
                //letter-spacing: 0
        ),
        'font-text-l': (
                font-family: $mainfont,
                font-size: $font-l,
                font-weight: $regular,
                line-height: 140%,
        ),
        'font-text-m': (
                font-family: $mainfont,
                font-size: $font-m,
                font-weight: $regular,
                line-height: 145%,
        ),
        'font-text-s': (
                font-family: $mainfont,
                font-size: $font-s,
                font-weight: $regular,
                line-height: 150%,
        ),
        'font-text-xs': (
                font-family: $mainfont,
                font-size: $font-xs,
                font-weight: $regular,
                line-height: 150%,
        ),
        'font-text-xxs': (
                font-family: $mainfont,
                font-size: $font-xxs,
                font-weight: $regular,
                line-height: 150%,
        ),
        'font-text-xxxs': (
                font-family: $mainfont,
                font-size: $font-xxxs,
                font-weight: $regular,
                line-height: 150%,
        ),
        'font-quote': (
                font-family: $mainfont,
                font-size: clamp($font-quote-min, 4vw, $font-quote-max),
                font-weight: $medium,
                line-height: 130%,
        )
);

;@import "sass-embedded-legacy-load-done:52";