@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_content_image_switch {
    margin: 48px 0;

    @include breakpoint(large down) {
        padding-bottom: 32px;

        .grid-container {
            padding-top: 32px;
        }

        figure img {
            width: 100%;
        }
    }

    @include breakpoint(xlarge) {
        min-height: 480px;

        &.reversed figure {
            margin-left: 50vw;
        }

        .grid-x {
            min-height: 480px;
        }

        figure {
            display: block;
            position: absolute;
            width: 50vw;
            height: 100%;

            img {
                width: 50vw;
                height: 100%;
                object-fit: cover;
                position: absolute;
            }
        }

        .youtube-holder {
            display: block;
            position: absolute;
            width: 50vw;
            height: 100%;
        }
    }

    .heading-h2 {
        margin-bottom: 20px;
    }

    .button {
        margin-top: 8px;
        text-decoration: none;

        @include breakpoint(small only) {
            width: 100%;
            text-align: center;
        }
    }

    @include breakpoint(xlarge) {
        .button-play {
            left: 25vw;
        }
    }

    video,
    .embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @include breakpoint(large down) {
            width: calc(100% + 32px);
            max-width: calc(100% + 32px);
            left: -16px;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .close-video {
        box-shadow: 3px 3px 3px 0px rgba($color-black, 0.25);
        cursor: pointer;
        width: 28px;
        height: 28px;
        position: absolute;
        top: 16px;
        right: 0;
        z-index: 2;
        display: none;

        @include breakpoint(xlarge) {
            right: 16px;
        }

        &:before {
            content: 'x';
            color: $color-white;
        }
    }
}

;@import "sass-embedded-legacy-load-done:111";